import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios-config';

const baseQueryApi = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  tagTypes: [
    'MAIL',
    'COMMUNICATION_SETTINGS',
    'DIRECT_MAIL',
    'ENVELOPE',
    'CHECKLIST_TEMPLATE',
    'NOTES',
    'AFTER_TASK_INSTRUCTION_TEMPLATE',
    'CHAT',

    /* sms */
    'SMS_TEMPLATE',

    /* email */
    'EMAIL_TEMPLATE',
    'MASS_EMAIL',
    'SCHEDULED_MASS_EMAIL',
    'DIRECT_MAIL_TEMPLATE',

    /* forms */
    'FORMS',

    /* pipeline */
    'PIPELINE_CATEGORY',
    'PIPELINE_STATUS',
    'CUSTOM_PIPELINE',
    'CUSTOM_PIPELINE_STAGES',

    'PIPELINE_CONTACTS',
  ],
  endpoints: () => ({}),
  keepUnusedDataFor: 300,
});

export default baseQueryApi;
