import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios-config';

// Configure chat API using Redux Toolkit's createApi
export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: axiosBaseQuery,
  tagTypes: ["CHAT_MESSAGES"],
  endpoints: (builder) => ({
    fetchChatMessages: builder.query({
      query: ({ chatId, params }) => ({
        url: `/chats/${chatId}/messages`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { chatId }) => {
        return [{ type: 'CHAT_MESSAGES', id: chatId }];
      },
    }),
  }),
});


export const { useFetchChatMessagesQuery, useLazyFetchChatMessagesQuery } = chatApi;
export default chatApi;

